/* eslint-disable no-restricted-globals */
import { Avatar, Box, Button, ButtonGroup, Center, Container, Divider, Flex, Heading, HStack, Icon, IconButton, Image, Link, LinkBox, Menu, MenuButton, MenuGroup, MenuItem, MenuList, SimpleGrid, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { IRecruiter, IUser } from "../../types/user";
import { FiMenu } from 'react-icons/fi'
import { useNavigate } from "react-router-dom";
import { text } from "../util/texts";
import { getFullName, isRecruiter } from "../../utils/users";
import { QuestionIcon, SettingsIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { HiPencil } from "react-icons/hi";
import { FaSignOutAlt } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";

interface HeaderProps {
  user?: IUser | IRecruiter,
  onHomeNav: () => void,
  onLogout: () => void,
}

const menuItemsByRole = {
  'user': [
    { label: 'Home', path: '/' },
    { label: 'Matches', path: '/jobMatches' },
    // { label: 'Invites', path: '/jobMatches?searchTerm=&status=invited' },
    { label: 'Profile', path: '/jobProfile' },
  ],
  'recruiter': [
    { label: 'Home', path: '/' },
    { label: 'Matches', path: '/profileMatches' },
    { label: 'Job Posts', path: '/jobPosts' },
  ],
}

const Header = ({
  user,
  onHomeNav,
  onLogout,
}: HeaderProps) => {

  const navigate = useNavigate();

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const isAuthenticated = user !== undefined;  
  const menuItems = isRecruiter(user) ? menuItemsByRole.recruiter : menuItemsByRole.user;

  const renderDesktopNavMenu = () => {
    return isAuthenticated ? (
      <Center gap={5}>
        {menuItems.map(mi => <Link as={RouterLink} to={mi.path}>{text('header.menu', mi.label, 0)}</Link>)}        
      </Center>
    ) :  null;
  }
  
  const renderDesktopProfileMenu = () => {
    return isAuthenticated ?
    (
      <Flex flex="1" justify="flex-end" align="center" gap={3}>
        <QuestionIcon />
        <SettingsIcon />
        <Menu closeOnSelect={true}>
          <MenuButton as={Avatar} name={getFullName(user)} src={user.avatarUrl} size="sm" cursor="pointer" />
          <MenuList minW='240px' color="black">
            <MenuGroup title='Profile'>
              <MenuItem icon={<AiOutlineUser />} onClick={() => navigate('/profile')}>User Profile</MenuItem>              
              <MenuItem icon={<FaSignOutAlt />} onClick={() => onLogout()}>Sign Out</MenuItem>              
            </MenuGroup>
          </MenuList>      
        </Menu>
      </Flex>
    ) : (
      <Flex flex="1" justify="flex-end" align="center" gap={3}>
        {/* <Button variant="outline">Sign Up</Button>
        <Button variant="outline">Sign In</Button> */}
      </Flex>
    )
  }

  const renderMobileMenu = () => {
    return (
      <Flex flex="1" justify="flex-end" align="center">
        <IconButton
          variant="ghost"
          icon={<FiMenu fontSize="1.25rem" />}
          aria-label="Open Menu"
        />
      </Flex>
    )
  }

  return (
    <Flex px={5} py={5} gap={6} backgroundColor="#162040" color="white">
      <Center mr={7}>
        {text('header.icon', 'kibo', 0)}        
      </Center>
      { isDesktop ? (
        <>
          {renderDesktopNavMenu()}
          {renderDesktopProfileMenu()}
        </>
      ) : (
        <>
          {renderMobileMenu()} 
        </>        
      )}
    </Flex>
  )
};

export default Header;