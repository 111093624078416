import React, { FC } from 'react';
import JobPostProvider from './JobPostProvider';
import JobProvider from './JobProvider';
import MatchProvider from './MatchProvider';
import RatingProvider from './RatingProvider';
import UserProvider from './UserStore';

type StoreProviderProps = {
  children: any
};

// eslint-disable-next-line react/function-component-definition
const StoreProvider: FC<StoreProviderProps> = ({ children }) => (
  <UserProvider>
    <JobProvider>
      <RatingProvider>
        <JobPostProvider>
          <MatchProvider>
            {children}
          </MatchProvider>
        </JobPostProvider>
      </RatingProvider>
    </JobProvider>
  </UserProvider>
);

export default StoreProvider;