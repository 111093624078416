import { Box, Button, Flex, HStack } from "@chakra-ui/react";
import { borders } from "../../../styles/common";
import { IJobPost, IJobPostWithStats } from "../../../types/job-post";
import { text } from "../../util/texts";
import { locationTag, salaryTag, workTypeTag } from '../../util/tags';
import { EditIcon } from "@chakra-ui/icons";
import Stat from "../../common/Stat";
import { HiPencil } from "react-icons/hi";
import { FaTimesCircle } from "react-icons/fa";
import moment from 'moment';

interface JobPostItemProps {
  jobPostWithStats: IJobPostWithStats,
  onEdit: () => void, 
  onClose: () => void,
}

let dollarUSLocale = Intl.NumberFormat('en-US');

const JobPostItem = ({
  jobPostWithStats,
  onEdit,
  onClose,
}: JobPostItemProps) => {
  const { jobPost, stats } = jobPostWithStats;
  return (
    <Box { ...borders.cardRaised } px={5} py={5}>
      {text('body.caption', `Posted ${moment(jobPost.postedDate).fromNow()}`, 0)}    
      {text('card.title', jobPost.companyJobTitle)}      
      {text('body.regular', jobPost.location, 2)}        
      {/* {text('body.regular', jobPost.companyJobDescription, 3)} */}
      <HStack gap={2} mb={4}>
        {salaryTag(dollarUSLocale.format(jobPost.salary))}
        {workTypeTag(jobPost.workType)}
        {locationTag(jobPost.locationType)}
      </HStack>
      <Flex gap={7} mb={3} { ...borders.card}>        
        <Stat name="Matches" value={stats.matches.toString()} link="/" linkLabel="View" size="sm" />
        <Stat name="Applications" value={stats.applied.toString()} link="/" linkLabel="View" size="sm" />
        <Stat name="Invites" value={stats.invited.toString()} link="/" linkLabel="View" size="sm" />
      </Flex>
      <HStack gap={3}>
        <Button variant="link" leftIcon={<HiPencil />} colorScheme="blue" onClick={onEdit}>Edit Job Post</Button>
        <Button variant="link" leftIcon={<FaTimesCircle />} colorScheme="orange" onClick={onClose}>Close Job Post</Button>
      </HStack>
    </Box>
  )
};  

export default JobPostItem;