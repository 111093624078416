import React from "react";
import {
  Box,
  Tag,
  Text,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { getColorForLevel } from "../../utils/proficiency-levels";
import { ProficiencyLevel } from "../../types/proficiency-level";
import { ICompetency } from "../../types/job";
import { get, startCase } from "lodash";
import MatchIndicator from "../shared/MatchIndicator";
import { text } from "../util/texts";
import { borders } from "../../styles/common";
import { IGenericCompetencyRating } from "../../types/rating";
import { IJobMatchCompetency } from "../../types/match";

interface CompetencyProps {
  competency: ICompetency;
  competencyRating?: IGenericCompetencyRating;
  competencyMatch?: IJobMatchCompetency;
  hideDescription?: boolean;
}

const Competency = ({
  competency,
  competencyRating,
  competencyMatch,
  hideDescription = false,
}: CompetencyProps) => {
  const showRating = competencyRating !== undefined;
  const showMatch = competencyMatch !== undefined;

  const renderExpectation = () => {
    if (!showMatch) return null;

    return (
      <Stack>
        {text('body.caption', 'Job Req.', 0)}
        <Tag
          colorScheme={getColorForLevel(competencyMatch.expectation)}
          // variant="outline"
          mb={1}>
            {startCase(ProficiencyLevel[competencyMatch.expectation])}
        </Tag>
      </Stack>
    )
  }
  const renderRating = () => {
    if (!showRating) return null;

    return (
      <Box mt={2} { ...borders.card }>
        <HStack>
          <Stack>
            {text('body.caption', 'Rating', 0)}
            <Tag
              colorScheme={getColorForLevel(competencyRating.rating)}
              // variant="outline"
              mb={1}>
                {startCase(ProficiencyLevel[competencyRating.rating])}
            </Tag>
          </Stack>
          {renderExpectation()}               
        </HStack>        
      </Box>      
    );
  }

  const renderMatchIndicator = () => {
    if (!showMatch) return null;

    return (
      <MatchIndicator matchOutcome={competencyMatch.matchOutcome} />
    )
  }

  const title = get(competency, 'title', get(competency, 'competency.title'));
  const description = get(competency, 'description', get(competency, 'competency.description'));

  return (
    <Box backgroundColor="white" { ...borders.cardRaised }>
      {renderMatchIndicator()}
      {text('card.title', title)}
      {!hideDescription && text('body.regular', description) }
      {renderRating()}
    </Box>    
  )
}

export default Competency;