import { Box, Center, Spinner, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { RatingContextValue, useRating } from '../../stores/RatingProvider';
import { text } from '../../components/util/texts';
import UserJobProfileManage from '../../components/user/profile/UserJobProfileManage';
import { CompetencyRatingMap } from '../../components/shared/CategoryRater';

const UserJobRatingContainer = () => {
  const { jobRatingId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  console.log('jobRatingId', jobRatingId)

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    rating,
    isFetchingRatings,
    fetchUserJobRating,
    updateUserJobRating,
  } = useRating() as RatingContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchUserJobRating(jobRatingId as string)
    }
  }, []);

  if (isFetchingRatings) {
    return <Spinner />
  }

  if (!currentUser || !rating) {
    return null;
  }

  console.log();

  const onSubmit = (updatedValues: CompetencyRatingMap) => {
    console.log('onSubmit', updatedValues);
    updateUserJobRating(rating.id, updatedValues).then(() => {
      navigate('/jobProfile')
      toast({
        title: 'Job profile updated!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    });
  }

  return (
    <Box px={20} py={15}>
      <UserJobProfileManage job={rating.job} jobRating={rating} onSubmit={onSubmit} />
    </Box>    
  )
};

export default UserJobRatingContainer;