import * as React from "react"
import {
  ChakraProvider,
} from "@chakra-ui/react"
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import App from "./App"
import StoreProvider from "../stores/StoresProvider"
import theme from "../styles/theme"

export const Root = () => (
  <ChakraProvider theme={theme}>
    <StoreProvider>      
      {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
      <Router>
        <App />
      </Router>      
    </StoreProvider>
  </ChakraProvider>
)
