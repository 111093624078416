import { Box, Heading, Spinner, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// import { jobMatchFilters } from "../../components/user/match/job-match-filters";
import JobMatchPage from "../../components/user/match/JobMatchPage";
import { text } from "../../components/util/texts";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { IFilter, IFilterValue, ISearchValue } from "../../types/ui/search";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const getDefaultFilterValues = (filters: IFilter[]) => {
  const filterDefaultValues: IFilterValue[] = [];
  filters.forEach(f => {
    if (f.defaultValue) {
      filterDefaultValues.push({
        slug: f.slug,
        selectedValue: f.defaultValue
      })
    }
  });
  return filterDefaultValues;
}

const dedupArray = (filterValues: IFilterValue[]) => {
  return filterValues.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.slug === value.slug
    ))
  )
}
const UserJobMatchContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()  

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    matches,
    filters,
    isFetchingMatches,
    fetchUserJobMatches,
  } = useMatch() as MatchContextValue;

  let [searchParams, setSearchParams] = useSearchParams();
  const statusQuery = searchParams.get('status');
  console.log('statusQuery', statusQuery)
  const initialFilterValues = statusQuery ? dedupArray([ { slug: 'status', selectedValue: statusQuery }, ...getDefaultFilterValues(filters)]) : getDefaultFilterValues(filters);
  console.log('initialFilterValues', initialFilterValues)
  const [filterValues, setFilterValues] = useState<IFilterValue[]>(initialFilterValues);

  useEffect(() => {
    if (currentUser) {
      fetchUserJobMatches(currentUser.id, { filterValues })
    }    
  }, []);  

  if (isFetchingMatches) {
    return <Spinner />
  }

  if (!currentUser || !matches) {
    return null;
  }

  const onSearch = (searchValue: ISearchValue) => {
    setFilterValues(searchValue.filterValues as IFilterValue[]);
    fetchUserJobMatches(currentUser.id, searchValue)
  }

  const onApply = (jobPostId: string) => {
    console.log('onApply', jobPostId)
    onOpen();
  }

  const onSave = (jobPostId: string) => {
    console.log('onSave', jobPostId)
  }

  const onReject = (jobPostId: string) => {
    console.log('onReject', jobPostId)
  }

  return (
    <>
      <JobMatchPage
        jobMatches={matches}
        filters={filters}
        filterValues={filterValues}
        onSearch={onSearch}
        onApply={onApply}
        onSave={onSave}
        onReject={onReject}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply To Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {text('body.lg', 'Apply at the employers job application page.', 3)}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose} leftIcon={<ExternalLinkIcon />}>
              Apply
            </Button>
            <Button variant='ghost' onClick={onClose}>Done</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>    
  )
};

export default UserJobMatchContainer;