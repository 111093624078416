import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { groupBy, includes } from "lodash";
import { IJob } from "../../types/job";
import { text } from "../util/texts";
import JobItem from "./JobItem";

interface JobSelectorProps {
  jobs: IJob[],
  existingJobIds: string[],
  onSelect: (jobId: string) => void,
}

const JobSelector = ({
  jobs,
  onSelect,
  existingJobIds,
}: JobSelectorProps) => {
  const availableJobs = jobs.filter(j => !includes(existingJobIds, j.id))
  const jobsByFamily = groupBy(availableJobs, 'jobFamily');

  return (
    <Box>
      {Object.keys(jobsByFamily).map(jobFamily => {
        const jobs = jobsByFamily[jobFamily];
        return (
          <Box mb={10}>
            {text('section.title', jobFamily)}
            <SimpleGrid columns={{ base: 1, md: 3 }} gap={3}>
              {jobs.map(job => {
                const onClick = () => {
                  onSelect(job.id);
                }

                return (
                  <Box>
                    <JobItem job={job} onClick={onClick} />
                  </Box>
                )
              })}
            </SimpleGrid>
          </Box>
        )
      })}
    </Box>
  )
}

export default JobSelector;