import React from "react";
import {
  Box,
  Tag,
  Text,
  Heading,
  TagLeftIcon,
  TagLabel,
  HStack,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Badge,
  Icon,
} from '@chakra-ui/react'
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
import { ICategory } from "../../types/job";
import { get } from "lodash";
import Competency from "./Competency";
import { ProficiencyLevel } from "../../types/proficiency-level";
import MatchPercentage from "../shared/MatchPercentage";
import { text } from "../util/texts";
import { borders } from "../../styles/common";
import { IGenericCompetencyCategoryRatingsContainer } from "../../types/rating";
import { IJobMatchCompetency } from "../../types/match";
import { getMatchPercentage } from "../../utils/proficiency-levels";

interface CompetencyCategoryProps {
  category: ICategory,
  categoryRating?: IGenericCompetencyCategoryRatingsContainer,
  categoryCompetencyMatches?: IJobMatchCompetency[],
}

const Category = ({
  category,
  categoryRating,
  categoryCompetencyMatches,
}: CompetencyCategoryProps) => {
  // const showRating = get(category, 'categoryId') !== undefined;
  // const showMatch = get(category, 'matchPercentage') !== undefined;

  const renderCompetencyCount = () => {
    if (!categoryRating) {
      return (
        <Text fontSize="sm">
          {category.competencies.length} Competencies
        </Text>
      );
    }

    const ratingCount = categoryRating.competencyRatings.filter(comp => {
      return comp.rating !== ProficiencyLevel.Not_Applicable
    })

    let colorScheme = 'green';
    let icon = CheckIcon;

    if (ratingCount.length === 0) {
      colorScheme = 'orange';
      icon = WarningIcon;
    }
    else if (ratingCount.length < categoryRating.competencyRatings.length) {      
      colorScheme = 'orange';
      icon = CheckIcon;
    }

    return (
      <>
        <Text fontSize="sm">
          {ratingCount.length} of {categoryRating.competencyRatings.length} Competencies Rated
        </Text>
        <Icon as={icon} color={colorScheme} />
        {/* <Tag variant="subtle" size="sm" colorScheme={colorScheme}>
          <TagLeftIcon boxSize='12px' as={icon} />&nbsp;
        </Tag> */}
      </>      
    );
  }

  const renderMatchPercentage = () => {
    if (!categoryCompetencyMatches) return null;

    const matchPercentage = getMatchPercentage(categoryCompetencyMatches);
    const color = matchPercentage > 80 ? 'green' : 'orange';

    return (
      <Box mr={3}>
        <MatchPercentage percentage={matchPercentage} color={color} size="sm" />
      </Box>
    ) 
  }

  const title = get(category, 'title', get(category, 'category.title'));
  const description = get(category, 'description', get(category, 'category.description'));

  return (
    <AccordionItem mb={3} border={0}>
      <AccordionButton { ...borders.cardRaised }>
        <Box flex='1' textAlign='left'>
          {text('section.subtitle', title)}
          <HStack mb={2}>
            {renderCompetencyCount()}
            {/* {renderRatingStatus()} */}
          </HStack>
        </Box>
        {renderMatchPercentage()}   
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4} { ...borders.noTop }>
        {text('body.lg', description, 5)}
        {text('label.lg', 'Competencies', 3)}
        <SimpleGrid columns={{sm: 1, md: 3}} spacing={6}>
          { category.competencies.map(competency => {
            const competencyRating = categoryRating?.competencyRatings.find(cr => cr.competencyId === competency.id);
            const competencyMatch = categoryCompetencyMatches?.find(cm => cm.competencyId === competency.id);
            return (
              <Competency
                competency={competency}
                competencyRating={competencyRating}
                competencyMatch={competencyMatch}
              />
            )
          })}
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>   
  )
}

export default Category;