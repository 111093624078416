import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import RecruiterHomePage from "../../components/recruiter/RecruiterHomePage";
import UserHomePage from "../../components/user/UserHomePage";
import { JobPostContextValue, useJobPost } from "../../stores/JobPostProvider";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { IRecruiter } from "../../types/user";

const RecruiterHomeContainer = () => {

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    matches,
    isFetchingMatches,
    fetchRecruiterJobMatch,
  } = useMatch() as MatchContextValue;

  const {
    jobPosts,
    isFetchingJobPosts,
    fetchJobPosts,
  } = useJobPost() as JobPostContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchRecruiterJobMatch(currentUser.id)
      fetchJobPosts()
    }    
  }, []);

  if (isFetchingMatches || isFetchingJobPosts) {
    return <Spinner />
  }

  if (!currentUser || !matches) {
    return null;
  }

  console.log('matches', matches)

  return (
    <RecruiterHomePage recruiter={currentUser as IRecruiter} jobMatches={matches} jobPosts={jobPosts} />
  )
};

export default RecruiterHomeContainer;