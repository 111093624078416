import React from "react";
import {
  Stat,
  StatHelpText,
  StatArrow,
} from '@chakra-ui/react'
import DotIcon from "../common/DotIcon";
import { MatchOutcome } from "../../types/proficiency-level";

interface MatchIndicatorProps {
  matchOutcome: MatchOutcome;
}

const getLabel = (result: number) => {
  if (result === 0) {
    return 'Matches Expectation';
  }
  else if (result < 0) {
    return 'Below Expectation';
  }
  
  return 'Exceeds Expectation';
}

const getIcon = (result: number) => {
  if (result === 0) {
    return (<DotIcon color='blue.500' mr={1} />);
  }
  else if (result < 0) {
    return (<StatArrow type='decrease' />);
  }
  
  return (<StatArrow type='increase' />);
}

const MatchIndicator = ({
  matchOutcome
}: MatchIndicatorProps) => {
  return (
    <>
      <Stat>
        <StatHelpText alignContent="flex-start">
          {getIcon(matchOutcome)}
          {getLabel(matchOutcome)}
        </StatHelpText>
      </Stat>
    </>
  )
}

export default MatchIndicator;