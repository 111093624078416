import { Box, Divider, Grid, GridItem, Input, InputGroup, InputLeftElement, Spinner, Text } from "@chakra-ui/react";
import { isArray } from "lodash";
import { useState, useEffect, useMemo } from "react";
import { IJobMatch } from "../../../types/match";
import { IJobPost } from "../../../types/job-post";
import { IFilter, IFilterValue, ISearchValue } from "../../../types/ui/search";
import JobMatchDetail from "./JobMatchDetail";
import JobMatchItem from "./JobMatchItem";
import FilterDetailGroup from "../../common/FilterDetailGroup";
import { text } from "../../util/texts";
import { borders, scrolls } from "../../../styles/common";
import { SearchIcon } from "@chakra-ui/icons";

interface JobMatchPageProps {
  jobMatches: IJobMatch[],
  selectedJobPostId?: string,
  filters: IFilter[],
  filterValues: IFilterValue[],
  onSearch: (searchValue: ISearchValue) => void,
  onApply: (jobPostId: string) =>  void,
  onSave: (jobPostId: string) => void,  
  onReject: (jobPostId: string) => void,
}

const JobMatchPage = ({
  jobMatches,
  selectedJobPostId,
  filters,
  filterValues,
  onSearch,
  onApply,
  onSave,
  onReject,
}: JobMatchPageProps) => {
  const [selectedJobMatch, setSelectedJobMatch] = useState<IJobMatch>();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const setByJobPostId = (jobPostId: string) => {
    const jobMatch = jobMatches.find(m => m.jobPostId === jobPostId);    
    if (jobMatch) {
      setSelectedJobMatch(jobMatch);
    }    
  }

  useEffect(() => {
    if (jobMatches.length > 0) {
      const jobPostId = selectedJobPostId || jobMatches[0].jobPostId;
      setByJobPostId(jobPostId)
    }    
  }, []);

  const renderFilter = () => {    
    const onUpdate = (updatedFilterValues: IFilterValue[]) => {
      onSearch({
        searchTerm,
        filterValues: updatedFilterValues,
      })
    }

    console.log('render filter', filters, filterValues);
    return (
      <FilterDetailGroup filters={filters} filterValues={filterValues} onUpdate={onUpdate} direction="row" />
    )
  }

  const renderLeftNav = () => {
    // backgroundColor={"gray.50"}
    return (
      <Box h="100%" py={5} backgroundColor={"#F1F7FE"}>
        <Box px={4} mb={3}>
          {text('section.title', `Job Matches (${jobMatches.length})`)}
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={<SearchIcon color='gray.500' />}
            />
            <Input type="text" placeholder='Search' backgroundColor="white" />
          </InputGroup>
        </Box>
        <Box { ...scrolls.yOverflowSubtle }>
          {jobMatches.map(jobMatch => {
            const onSelect = () => {
              setByJobPostId(jobMatch.jobPostId);
            }
            const isActive = selectedJobMatch?.jobPostId === jobMatch.jobPostId;

            
            // { ...borders.card }
            return (
              <Box
                backgroundColor={isActive ? '#DBE5F5' : '#F1F7FE'}
                _hover={{
                  backgroundColor: "#DBE5F5",
                }}    
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >              
                <JobMatchItem 
                  jobMatch={jobMatch} 
                  onClick={onSelect}
                />
                <Divider />
              </Box>            
            )
          })}
        </Box>        
      </Box>
    );
  }

  const renderDetail = () =>  {
    if (!jobMatches || jobMatches.length === 0) {
      return <Text>No Job Matches</Text>
    }

    if (!selectedJobMatch) {
      return <Spinner size="xl" />
    }

    return (
      <JobMatchDetail
        jobMatch={selectedJobMatch}
        onApply={() => onApply(selectedJobMatch.jobPostId)}
        onSave={() => onSave(selectedJobMatch.jobPostId)}
        onReject={() => onReject(selectedJobMatch.jobPostId)}
      />
    );
  }

  return (
    <Box
      h="100%"     
    >
      <Box>
        <Box pos="fixed" h="100%" w="25%" { ...scrolls.yOverflowSubtle } >
          {renderLeftNav()}
        </Box>
        <Box pos="fixed" h="100%" w="60%" ml="25%" px={7} py={3} { ...scrolls.yOverflowSubtle }>
          {renderDetail()}    
        </Box>
        <Box pos="fixed" h="100%" w="15%" ml="85%" px={7} py={3} { ...scrolls.yOverflowSubtle } { ...borders.left}>
          {renderFilter()}
        </Box>
      </Box>      
    </Box>
  )
}

export default JobMatchPage;