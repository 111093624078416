import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { IFilter, IFilterValue } from "../../types/ui/search";
import { text } from "../util/texts";
import FilterDetail from "./FilterDetail";

interface FilterDetailGroupProps {
  filters: IFilter[],
  filterValues?: IFilterValue[],
  onUpdate: (updatedFilterValues: IFilterValue[]) => void,
  direction: 'row' | 'column',
}

const FilterDetailGroup = ({
  filters,
  filterValues,
  onUpdate,
  direction,
}: FilterDetailGroupProps) => {
  // const [currentFilterValues, setCurrentFilterValues] = useState<IFilterValue[]>(filterValues || []);

  const getSelectHandler = (slug: string) => (value: string | string[]) => {
    const updatedFilterValues: IFilterValue[] = filterValues || [];
    const existingFilterValue: IFilterValue | undefined = updatedFilterValues.find(fv => fv.slug === slug);
    if (!existingFilterValue) {
      updatedFilterValues.push({
        slug,
        selectedValue: value
      })
    }
    else {
      existingFilterValue.selectedValue = value;
    }
        
    onUpdate(updatedFilterValues);
    // setCurrentFilterValues([ ...updatedFilterValues]);
  }

  const renderFilterDetailItems = () => {
    return filters.map(filter => {
      const filterValue = filterValues?.find(fv => fv.slug === filter.slug);
      return (
        <FilterDetail
          filter={filter}
          filterValue={filterValue}
          onSelect={getSelectHandler(filter.slug)}
          optionRenderer={filter.optionRenderer}
        />
      )
    })
  }

  return (
    <Box>
      {text('section.subtitle', 'Filter')}
      {direction === 'row' ? (
        <VStack gap={5} align="flex-start">
          {renderFilterDetailItems()}
        </VStack>
      ) : (
        <HStack gap={7} align="flex-start">
          {renderFilterDetailItems()}
        </HStack>
      )}
    </Box>
  )
};

export default FilterDetailGroup;