import { Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import JobPostsPage from '../../components/recruiter/jobPost/JobPostsPage';
import { UserContextValue, useUser } from '../../stores/UserStore';
import { JobPostContextValue, useJobPost } from '../../stores/JobPostProvider';

const RecruiterJobPostsContainer = () => {
  const {
    currentUser,
  } = useUser() as UserContextValue;
  
  const {
    jobPosts,
    isFetchingJobPosts,
    fetchJobPosts,
  } = useJobPost() as JobPostContextValue;
  
  useEffect(() => {
    if (currentUser) {
      fetchJobPosts()
    }
  }, []);
  
  if (isFetchingJobPosts) {
    return <Spinner />
  }
  
  if (!currentUser || !jobPosts) {
    return null;
  }

  return (
    <JobPostsPage activeJobPosts={jobPosts} closedJobPosts={jobPosts} />
  )
};

export default RecruiterJobPostsContainer;