import { Box, Button, Flex, Heading, HStack, Link, SimpleGrid, Text } from "@chakra-ui/react";
import { IJobMatch, JobMatchStatus } from "../../types/match";
import { IRecruiter } from "../../types/user";
import Stat from "../common/Stat";
import { text } from "../util/texts";
import ProfileMatchItem from "./match/ProfileMatchItem";
import { get } from "lodash";
import { HiPencil, HiPlusCircle } from "react-icons/hi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { IJobPost, IJobPostWithStats } from "../../types/job-post";
import { borders } from "../../styles/common";

interface RecruiterHomePageProps {
  recruiter: IRecruiter;
  jobMatches: IJobMatch[];
  jobPosts?: IJobPostWithStats[];
}

const RecruiterHomePage = ({
  recruiter,
  jobMatches,
  jobPosts,
}: RecruiterHomePageProps) => {
  const navigate = useNavigate();

  const topMatches = jobMatches.slice(0, 3);
  const needsReview = jobMatches.filter(m => get(m, 'userStatus.status') === JobMatchStatus.NeedsReview.toString()).slice(0, 3);
  const applied = jobMatches.filter(m => get(m, 'userStatus.status') === JobMatchStatus.CandidateApplied.toString()).slice(0, 3);
  const invites = jobMatches.filter(m => get(m, 'recruiterStatus.status') === JobMatchStatus.RecruiterInvited.toString()).slice(0, 3);  

  const renderSection = (title: string, link: string, jobMatches: IJobMatch[]) => {    
    return (
      <Box mb={14}>
        <HStack gap={3}>
          {text('section.title', title)}
          <Link as={RouterLink} to={link} textDecoration="underline">See All</Link>
        </HStack>
        {jobMatches.length === 0 && text('body.regular', 'None')}
        {jobMatches.length > 0 && (
          <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={3}>
            {jobMatches.map(match => {
              const onClick = () => navigate('/profileMatches');
              return (
                <Box shadow="xl" borderRadius={6} border="1px" borderColor="gray.100">
                  <ProfileMatchItem
                    jobMatch={match}
                    onClick={onClick}
                  />
                </Box>
              )
            })}
          </SimpleGrid>
        )}                
      </Box>
    )
  }


  return (
    <Box px={20} py={15}>
      {text('page.title', `Welcome back, ${recruiter.firstName}!`, 2)}
      <HStack mb={7} gap={3}>
        <Button variant="link" leftIcon={<HiPencil />} colorScheme="blue" as={RouterLink} to="/jobPosts">{text('body.lg', 'Update Your Job Posts', 0)}</Button>
        <Button variant="link" leftIcon={<HiPlusCircle />} colorScheme="green" as={RouterLink} to="/jobPost/selectJob">{text('body.lg', 'Post A New Job', 0)}</Button>
      </HStack>
      {text('section.title', 'Summary')}
      <Flex gap={10} mb={14} { ...borders.cardRaisedBold } p={8}>
        <Stat name="New Matches" value={`${needsReview.length}`} link="/profileMatches" linkLabel="View" />        
        <Stat name="Applications" value={`${applied.length}`} link="/profileMatches?searchTerm=&status=applied" linkLabel="View" />
        <Stat name="Invites" value={`${invites.length}`} link="/profileMatches?searchTerm=&status=invited" linkLabel="View" />
        <Stat name="Job Posts" value={`${jobPosts?.length || 0}`} link="/jobPosts" linkLabel="View" />
      </Flex>
      <Box>
        {renderSection('Top Matches', '/profileMatches', topMatches)}
        {renderSection('Recent Applications', '/profileMatches', applied)}        
        {renderSection('Invited to Apply', '/profileMatches', invites)}
      </Box>
    </Box>    
  )
};

export default RecruiterHomePage;