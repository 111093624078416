import React from 'react';
import {
  RouteObject,
} from 'react-router-dom';
import HomeContainer from './HomeContainer';
import Login from './Login';
import RecruiterMatchContainer from './recruiter/RecruiterMatchContainer';
import UserJobProfileContainer from './user/UserJobProfileContainer';
import UserJobMatchContainer from './user/UserJobMatchContainer';
import RecruiterJobPostsContainer from './recruiter/RecruiterJobPostsContainer';
import UserJobRatingContainer from './user/UserJobRatingContainer';
import ProfileContainer from './ProfileContainer';
import RecruiterJobPostContainer from './recruiter/RecruiterJobPostContainer';
import RecruiterJobPostCreateContainer from './recruiter/RecruiterJobPostCreateContainer';
import RecruiterJobSelectContainer from './recruiter/RecruiterJobSelectContainer';
import UserJobSelectContainer from './user/UserJobSelectContainer';
import UserJobPostCreateContainer from './user/UserJobPostCreateContainer';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <HomeContainer />,
  },
  {
    path: '/jobMatches',
    element: <UserJobMatchContainer />,
  },  
  {
    path: '/jobProfile',
    element: <UserJobProfileContainer />
  },  
  {
    path: '/jobRating/:jobRatingId',
    element: <UserJobRatingContainer />
  },
  {
    path: '/jobProfile/selectJob',
    element: <UserJobSelectContainer />
  },
  {
    path: '/jobProfile/create/:jobId',
    element: <UserJobPostCreateContainer />,
  },
  {
    path: '/profileMatches',
    element: <RecruiterMatchContainer />,
  },
  {
    path: '/jobs',
    element: <RecruiterJobSelectContainer />,
  },
  {
    path: '/jobPosts',
    element: <RecruiterJobPostsContainer />,
  },
  {
    path: '/jobPost/selectJob',
    element: <RecruiterJobSelectContainer />
  },
  {
    path: '/jobPost/create/:jobId',
    element: <RecruiterJobPostCreateContainer />,
  },
  {
    path: '/jobPost/:jobPostId',
    element: <RecruiterJobPostContainer />,
  },
  {
    path: '/profile',
    element: <ProfileContainer />,
  },
  {
    path: '/login',
    element: <Login />
  }
];

export default routes;
