import { Box, Divider, Grid, GridItem, Input, InputGroup, InputLeftElement, Spinner, Text } from "@chakra-ui/react";
import { isArray } from "lodash";
import { useState, useEffect } from "react";
import { IJobMatch } from "../../../types/match";
import { IJobPost } from "../../../types/job-post";
import { IFilter, IFilterValue, ISearchValue } from "../../../types/ui/search";
import FilterDropdownGroup from "../../common/FilterDropdownGroup";
import { profileMatchFilters } from "./profile-match-filters";
import ProfileMatchDetail from "./ProfileMatchDetail";
import ProfileMatchItem from "./ProfileMatchItem";
import { borders, scrolls } from "../../../styles/common";
import FilterDetailGroup from "../../common/FilterDetailGroup";
import { SearchIcon } from "@chakra-ui/icons";
import { text } from "../../util/texts";

interface ProfileMatchPageProps {
  jobMatches: IJobMatch[],
  selectedUserId?: string,
  selectedJobPostId?: string,
  filters: IFilter[],
  filterValues: IFilterValue[],
  onSearch: (searchValue: ISearchValue) => void,
  onApply: (jobPostId: string) =>  void,
  onSave: (jobPostId: string) => void,  
  onReject: (jobPostId: string) => void,
}

const filters: IFilter[] = profileMatchFilters;
const filterDefaultValues: IFilterValue[] = [];
filters.forEach(f => {
  if (f.defaultValue) {
    filterDefaultValues.push({
      slug: f.slug,
      selectedValue: f.defaultValue
    })
  }
});

const ProfileMatchPage = ({
  jobMatches,
  selectedUserId,
  selectedJobPostId,
  filters,
  filterValues,
  onSearch,
  onApply,
  onSave,
  onReject
}: ProfileMatchPageProps) => {  
  // const [jobPostId, setJobPostId] = useState<string>(selectedJobPostId || jobPosts[0].id);  
  console.log('jobMatches', jobMatches)
  const [selectedProfileMatch, setSelectedProfileMatch] = useState<IJobMatch>();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const setByUserIdAndJobPost = (userId: string, jobPostId: string) => {
    const jobMatch = jobMatches.find(m => m.userId === userId && m.jobPostId === jobPostId);    
    if (jobMatch) {
      setSelectedProfileMatch(jobMatch);
    }    
  }

  useEffect(() => {
    if (jobMatches.length > 0) {
      const userId = selectedUserId || jobMatches[0].userId;
      const jobPostId = selectedJobPostId || jobMatches[0].jobPostId
      setByUserIdAndJobPost(userId, jobPostId);
    }
  }, []);

  const renderFilter = () => {    
    const onUpdate = (updatedFilterValues: IFilterValue[]) => {
      onSearch({
        searchTerm,
        filterValues: updatedFilterValues,
      })
    }

    console.log('render filter', filters, filterValues);
    return (
      <FilterDetailGroup filters={filters} filterValues={filterValues} onUpdate={onUpdate} direction="row" />
    )
  }

  const renderLeftNav = () => {
    return (
      <Box h="100%" py={5} backgroundColor={"#F1F7FE"}>
        <Box px={4} mb={3}>
          {text('section.title', `Profile Matches (${jobMatches.length})`)}
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={<SearchIcon color='gray.500' />}
            />
            <Input type="text" placeholder='Search' backgroundColor="white" />
          </InputGroup>
        </Box>
        <Box { ...scrolls.yOverflowSubtle }>
          {jobMatches.map(jobMatch => {
            const onSelect = () => {
              setByUserIdAndJobPost(jobMatch.userId, jobMatch.jobPostId);
            }
            const isActive = selectedProfileMatch?.userId === jobMatch.userId && selectedProfileMatch?.jobPostId === jobMatch.jobPostId;

            return (
              <Box
                backgroundColor={isActive ? '#DBE5F5' : '#F1F7FE'}
                _hover={{
                  backgroundColor: "#DBE5F5",
                }}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <ProfileMatchItem 
                  jobMatch={jobMatch} 
                  onClick={onSelect}
                />
                <Divider />
              </Box>            
            )
          })}
        </Box>        
      </Box>
    );
  }

  const renderDetail = () =>  {
    if (!jobMatches || jobMatches.length === 0) {
      return <Text>No Job Matches</Text>
    }

    if (!selectedProfileMatch) {
      return <Spinner size="xl" />
    }

    return (
      <ProfileMatchDetail jobMatch={selectedProfileMatch} />
    );
  }

  // return (
  //   <Box
  //     h="100%"      
  //   >
  //     <Box zIndex={100} mb={3}>
  //       {renderFilter()}
  //     </Box>
  //     <Box>
  //       <Box pos="fixed" h="100%" w="25%" overflow="auto">
  //         {renderLeftNav()}
  //       </Box>
  //       <Box pos="fixed" h="100%" w="75%" ml="25%" overflow="auto" px={7} py={3}>
  //         {renderDetail()}    
  //       </Box>
  //     </Box>      
  //   </Box>
  // )

  return (
    <Box
      h="100%"      
    >
      <Box>
        <Box pos="fixed" h="100%" w="25%" >
          {renderLeftNav()}
        </Box>
        <Box pos="fixed" h="100%" w="60%" ml="25%" px={7} py={3} { ...scrolls.yOverflowSubtle }>
          {renderDetail()}    
        </Box>
        <Box pos="fixed" h="100%" w="15%" ml="85%" px={7} py={3} { ...scrolls.yOverflowSubtle } { ...borders.left}>
          {renderFilter()}
        </Box>
      </Box>      
    </Box>
  )
}

export default ProfileMatchPage;