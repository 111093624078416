import { Box, Center, Spinner, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { JobContextValue, useJob } from '../../stores/JobProvider';
import { text } from '../../components/util/texts';
import JobSelector from '../../components/shared/JobSelector';
import JobRater from '../../components/shared/JobRater';
import JobPost from '../../components/recruiter/jobPost/JobPost';
import { UserRole } from '../../types/user';
import { CompetencyRatingMap } from '../../components/shared/CategoryRater';
import { RatingContextValue, useRating } from '../../stores/RatingProvider';

const UserJobPostCreateContainer = () => {
  const { jobId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    job,
    isFetchingJobs,
    fetchJob,
  } = useJob() as JobContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJob(jobId as string)
    }
  }, []);

  const {
    createUserJobRating
  } = useRating() as RatingContextValue;

  if (isFetchingJobs) {
    return <Spinner />
  }

  if (!currentUser || !job) {
    return null;
  }

  const onSubmit = (updatedValues: CompetencyRatingMap) => {
    console.log('onSubmit', updatedValues);
    createUserJobRating(job.id, updatedValues).then(() => {
      navigate('/jobProfile')
      toast({
        title: 'Job added to profile!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    })
  }
  
  return (
    <Box px={20} py={15}>
      {text('page.title', job.title)}
      {text('page.instruction', 'Choose the most accurate description for yourself for each competency.', 5)}
      <JobRater job={job} ratingRole={UserRole.User} onSubmit={onSubmit} />
    </Box>    
  )
};

export default UserJobPostCreateContainer;