import { Box, Button, Flex, HStack, Link, SimpleGrid } from "@chakra-ui/react";
import { IJobMatch, JobMatchStatus } from "../../types/match";
import { IJobRating } from "../../types/rating";
import { IUser } from "../../types/user";
import Stat from "../common/Stat";
import { text } from "../util/texts";
import JobMatchItem from "./match/JobMatchItem";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { HiEye, HiPlusCircle } from "react-icons/hi";
import { borders } from "../../styles/common";

interface UserHomePageProps {
  user: IUser;
  jobMatches: IJobMatch[];
}

const UserHomePage = ({
  user,
  jobMatches,
}: UserHomePageProps) => {
  const navigate = useNavigate();

  const topMatches = jobMatches.slice(0, 3);
  const needsReview = jobMatches.filter(m => get(m, 'userStatus.status') === JobMatchStatus.NeedsReview.toString()).slice(0, 3);
  const invites = jobMatches.filter(m => get(m, 'recruiterStatus.status') === JobMatchStatus.RecruiterInvited.toString()).slice(0, 3);
  const applied = jobMatches.filter(m => get(m, 'userStatus.status') === JobMatchStatus.CandidateApplied.toString()).slice(0, 3);

  const renderSection = (title: string, link: string, jobMatches: IJobMatch[]) => {
    return (
      <Box mb={14}>
        <HStack gap={3}>
          {text('section.title', title)}
          <Link as={RouterLink} to={link} textDecoration="underline">See All</Link>
        </HStack>     
        {jobMatches.length === 0 && text('body.regular', 'None')}
        {jobMatches.length > 0 && (
          <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={3}>
            {jobMatches.map(match => {
              const onClick = () => navigate('/jobMatches')
              return (
                <Box shadow="xl" borderRadius={6} border="1px" borderColor="gray.100">
                  <JobMatchItem
                    jobMatch={match}
                    onClick={onClick}
                  />
                </Box>
              )
            })}
          </SimpleGrid>
        )}              
      </Box>
    )
  }
  return (
    <Box px={20} py={15}>
      {text('page.title', `Welcome back, ${user.firstName}!`, 2)}
      <HStack mb={7} gap={3}>
        <Button variant="link" leftIcon={<HiEye />} colorScheme="blue" as={RouterLink} to="/jobProfile">{text('body.lg', 'View Job Profile', 0)}</Button>
        <Button variant="link" leftIcon={<HiPlusCircle />} colorScheme="green" as={RouterLink} to="/jobProfile/selectJob">{text('body.lg', 'Add Job to Profile', 0)}</Button>
      </HStack>
      {text('section.title', 'Your quick summary')}
      <Flex gap={10} mb={14} { ...borders.cardRaisedBold } p={8}>        
        <Stat name="New Matches" value={`${needsReview.length}`} link="/jobMatches" linkLabel="View" />        
        <Stat name="Invites" value={`${invites.length}`} link={'/jobMatches?searchTerm=&status=invited'} linkLabel="View" />
        <Stat name="Applied" value={`${applied.length}`} link={'/jobMatches?searchTerm=&status=applied'} linkLabel="View" />
      </Flex>
      <Box>
        {renderSection('Top Matches', '/jobMatches', topMatches)}
        {renderSection('Recent Invites', '/jobMatches?searchTerm=&status=invited', invites)}
        {renderSection('Recently Applied', '/jobMatches?searchTerm=&status=applied', applied)}
      </Box>
    </Box>    
  )
}

export default UserHomePage;