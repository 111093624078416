import { Heading, Text } from "@chakra-ui/react"

interface TextDesc {
  fontSize: string,
  mb: number,
  weight?: string,
  color?: string,
  type?: 'heading' | 'text',
}

export type TextDescKey = 'header.icon' | 'header.menu' | 'page.title' | 'page.subtitle' | 'page.instruction' | 
                'stat.value' | 'stat.value.sm'  |
                'section.title' | 'section.subtitle' | 'section.instruction' | 'section.hint' |
                'card.title' | 'card.subtitle' | 'bold.lg' | 'bold.regular' |
                'body.xl' | 'body.lg' | 'body.regular' | 'body.sm' | 'body.caption' | 'body.hint' | 
                'label.lg' | 'label.regular' | 'label.sm';

export const TEXT_DISPLAY_DESCRIPTORS: {[key in TextDescKey]: TextDesc} = {
  'header.icon': { fontSize: '4xl', mb: 1, type: 'heading' },
  'header.menu': { fontSize: 'xl', mb: 1, weight: 'semibold', type: 'heading' },
  'page.title': { fontSize: '5xl', mb: 4 },
  'page.subtitle': { fontSize: '3xl', mb: 3, color: 'gray.600'},
  'page.instruction': { fontSize: 'xl', mb: 3, color: 'gray.700' },
  'stat.value': { fontSize: '4xl', mb: 0, type: 'heading' },
  'stat.value.sm': { fontSize: 'xl', mb: 0, type: 'heading' },
  'section.title': { fontSize: '3xl', mb: 3 },
  'section.subtitle': { fontSize: '2xl', mb: 2 },
  'section.instruction': { fontSize: 'lg', mb: 3, color: 'gray.700' },
  'section.hint': { fontSize: 'md', mb: 1, color: 'gray.600' },
  'card.title': { fontSize: '2xl', mb: 1 },
  'card.subtitle': { fontSize: 'xl', mb: 1 },
  'bold.lg': { fontSize: 'lg', mb: 1, weight: 'semibold' },  
  'bold.regular': { fontSize: 'md', mb: 1, weight: 'semibold' },  
  'body.xl': { fontSize: 'xl', mb: 1 },
  'body.lg': { fontSize: 'lg', mb: 1 }, 
  'body.regular': { fontSize: 'md', mb: 1 },  
  'body.sm': { fontSize: 'sm', mb: 1 },
  'body.caption': { fontSize: 'xs', mb: 1 },
  'body.hint': { fontSize: 'sm', mb: 1, color: 'gray.600' },  
  'label.lg': { fontSize: 'xl', mb: 1, weight: 'semibold' },
  'label.regular': { fontSize: 'md', mb: 1, weight: 'semibold' },
  'label.sm': { fontSize: 'sm', mb: 1, weight: 'semibold' },  
}

export const text = (key: TextDescKey, text: string, mb?: number, color?: string ) => {
  const textDesc = TEXT_DISPLAY_DESCRIPTORS[key];
  const isHeading = textDesc?.type === 'heading' || key.endsWith('title') || key.endsWith('subtitle')  

  if (!textDesc) return null;

  const optionalPops: any = {};
  if (color) optionalPops['color'] = color;
  else if (textDesc.color) optionalPops['color'] = textDesc.color;

  if (textDesc.weight) optionalPops['fontWeight'] = textDesc.weight;

  const mbComputed = mb !== undefined ? mb : textDesc.mb;

  if (isHeading) {
    return <Heading fontSize={textDesc.fontSize} mb={mbComputed} { ...optionalPops}>{text}</Heading>;
  }

  return <Text fontSize={textDesc.fontSize} mb={mbComputed} { ...optionalPops}>{text}</Text>;
}