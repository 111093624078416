import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import UserHomePage from "../../components/user/UserHomePage";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from '../../stores/UserStore';

const UserHomeContainer = () => {

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    matches,
    isFetchingMatches,
    fetchUserJobMatches,
  } = useMatch() as MatchContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchUserJobMatches(currentUser.id)
    }    
  }, []);

  if (isFetchingMatches) {
    return <Spinner />
  }

  if (!currentUser || !matches) {
    return null;
  }

  return (
    <UserHomePage user={currentUser} jobMatches={matches} />
  )
};

export default UserHomeContainer;