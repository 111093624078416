import { Box, Heading, Text } from "@chakra-ui/react";
import { IUser } from "../../types/user";
import Header from "./Header";
import {
  useNavigate,
} from "react-router-dom";

interface LayoutProps {
  user?: IUser,
  children?: any,
  onLogout: () => void,
}

const Layout = ({
  user,
  children,
  onLogout,
}: LayoutProps) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Header user={user} onHomeNav={() => navigate('/')} onLogout={onLogout} />
      {children}
    </Box>
  )
};

export default Layout;