import { Box, Button, Heading, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { renderInput, renderRadios } from "../../util/forms";
import { IUser } from "../../../types/user";
interface UserProfileProps {
  user?: IUser;
  onSubmit: (values: any) => void;
}

const required = { isRequired: true };
const notRequired = { isRequired: false };

const UserProfile = ({
  user,
  onSubmit,
}: UserProfileProps) => {
  return (
    <Box>
      <Formik
          initialValues={{
            username: user?.username || '',
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            location: user?.location || '',
            summary: user?.summary || '',
            locationType: user?.preferences.locationType || '',
            workType: user?.preferences.workType || '',
            salary: user?.preferences.salary || '',
          }}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <Heading fontSize="2xl">Basic Information</Heading>
                {renderInput('text', 'firstName', 'First Name', required, errors, touched)}
                {renderInput('text', 'lastName', 'Last Name', required, errors, touched)}
                {renderInput('email', 'email', 'Email', required, errors, touched)}
                {renderInput('text', 'location', 'Location (e.g. City, State)', required, errors, touched)}
                {renderInput('text', 'summary', 'Brief Intro', required, errors, touched)}
                <Heading fontSize="2xl">Preferences</Heading>
                {renderRadios('locationType', 'Location Type', [
                  { label: 'Remote', slug: 'remote' },
                  { label: 'Office', slug: 'office' },
                  { label: 'Hybrid', slug: 'hybrid' },
                ], required, errors, touched)}
                {renderRadios('workType', 'Work Type', [
                  { label: 'Contract', slug: 'contract' },
                  { label: 'Full-Time', slug: 'full-time' },
                  { label: 'Part-Time', slug: 'part-time' },
                ], required, errors, touched)}
                {renderInput('text', 'salary', 'Salary', required, errors, touched)}
                <Button type="submit" colorScheme={"blue"}>Submit</Button>
              </VStack>
            </form>
          )}
        </Formik>
    </Box>
  )
}

export default UserProfile;