import '@fontsource/inter/400.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/700.css'
import '@fontsource/tangerine/400.css'
import '@fontsource/tangerine/700.css'

import React, { ReactElement, ReactNode, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from '../components/layout/Layout';
import { UserContextValue, useUser } from '../stores/UserStore';
import routes from '../containers/routes'
import { includes } from 'lodash'
// import Helmet from 'react-helmet';
// import { includes, isEmpty } from 'lodash';
// import { routes, RouteDescriptor } from '../pages/index';
// import ScrollTop from '../components/ScrollTop';

const App = () => {  
  const location = useLocation();
  const navigate = useNavigate();

  const {
    currentUser,
    isFetchingCurrentUser,
    fetchCurrentUser,
    fetchDemoUsers,
    logoutUser,
  } = useUser() as UserContextValue;

  useEffect(() => {
    fetchCurrentUser()
    fetchDemoUsers()
  }, []);

  // useEffect(() => {
  //   const currentPath = location.pathname + location.search;
  //   ReactGA.pageview(currentPath);
  // }, [location]);

  if (isFetchingCurrentUser) {
    return <Spinner />;
  }  

  const isAuthenticated = currentUser !== undefined;


  if (isAuthenticated && location.pathname === '/login') {
    navigate('/');
    return null;
  }

  const validNotAuthenticatedPages = ['/login', '/error', '/help'];
  if (!isAuthenticated && !includes(validNotAuthenticatedPages, location.pathname)) {
    navigate('/login');
    return null;
  }

  const onLogout = async () => {
    await logoutUser();
  }

  const renderWithLayout = (element: ReactNode): ReactNode => {    
    return (
      <Layout user={currentUser} onLogout={onLogout}>
        {element}
      </Layout>
    )
  }

  const renderRoute = (route: RouteObject) => {    
    return (
      <Route
        path={route.path}
        element={renderWithLayout(route.element)}
      />
    )
  }

  return (
    <>
      {/* <Helmet>
        <title>{currentTenant.name}</title>
        <meta name="description" content={currentTenant.description} />
        <link rel="icon" href={currentTenant.uiPreferences.favIconUrl} />
      </Helmet> */}      
      <Routes>
        {routes.map(route => renderRoute(route))}
      </Routes>  
    </>
  );
};

export default App;