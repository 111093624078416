import { Box, Center, Spinner, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { JobPostContextValue, useJobPost } from '../../stores/JobPostProvider';
import { text } from '../../components/util/texts';
import JobPost from '../../components/recruiter/jobPost/JobPost';
import { CompetencyRatingMap } from '../../components/shared/CategoryRater';

const RecruiterJobPostContainer = () => {
  const { jobPostId } = useParams();

  const navigate = useNavigate();
  const toast = useToast()

  console.log('jobPostId', jobPostId)

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    jobPost,
    isFetchingJobPosts,
    fetchJobPost,
    updateJobPost,
  } = useJobPost() as JobPostContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJobPost(jobPostId as string)
    }
  }, []);

  if (isFetchingJobPosts) {
    return <Spinner />
  }

  if (!currentUser || !jobPost) {
    return null;
  }

  console.log();

  const onSubmit = (jobPostDetails: any, updatedValues: CompetencyRatingMap) => {
    console.log('onSubmit', jobPostDetails, updatedValues);
    updateJobPost(jobPostId as string, jobPostDetails, updatedValues).then(() => {
      navigate('/jobPosts')
      toast({
        title: 'Job Post updated!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    });
  }

  return (
    <Box px={20} py={15}>
      <JobPost job={jobPost.jobPost.job} jobPost={jobPost.jobPost} onSubmit={onSubmit} />
    </Box>    
  )
};

export default RecruiterJobPostContainer;