import { Box, Button, Center, Container, Flex, Heading, Select, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import { string } from "prop-types";
import { useEffect, useState } from "react";
import { text } from "../components/util/texts";
import { UserContextValue, useUser } from "../stores/UserStore";
import { borders } from "../styles/common";

const Login = () => {
  const {
    demoUsers,
    isFetchingDemoUsers,
    loginUser
  } = useUser() as UserContextValue;  

  const [jobSeeker, setJobSeeker] = useState<string>('');
  const [recruiter, setRecruiter] = useState<string>('');

  if (isFetchingDemoUsers) {
    return <Spinner />;
  }

  const onJSLogin = async () => {
    if (jobSeeker !== '') {
      await loginUser(jobSeeker)
    }      
  }

  const onRecruiterLogin = async () => {
    if (recruiter !== '') {
      await loginUser(recruiter)
    }    
  }

  const renderUsers = (role: string) => {
    return demoUsers[role].map((u: any) => {
      return (
        <option value={u.email}>{u.firstName} {u.lastName}</option>
      )
    })
  }

  return (
    <Container mt={10} { ...borders.cardRaised}>
      {text('section.title', 'Login to Kibo', 5)}
      <SimpleGrid columns={2} spacing={3}>
        <Box>
          <Select mb={3} onChange={(ev) => setJobSeeker(ev.target.value)}>
            <option value="">Select Demo Job Seeker</option>
            {renderUsers('user')}
          </Select>
          <Button colorScheme="blue" onClick={onJSLogin} disabled={jobSeeker === ''}>Login as Job Seeker</Button>
        </Box>
        <Box>
          <Select mb={3} onChange={(ev) => setRecruiter(ev.target.value)}>
            <option value="">Select Demo Recruiter</option>
            {renderUsers('recruiter')}
          </Select>
          <Button colorScheme="orange" onClick={onRecruiterLogin} disabled={recruiter === ''}>Login as Recruiter</Button>
        </Box>
      </SimpleGrid>      
    </Container>
  )
};

export default Login;