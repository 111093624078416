import { Accordion, Badge, Box, Button, Divider, Grid, GridItem, Heading, HStack, Icon, Stat, StatLabel, StatNumber, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { IJobMatch, JobMatchStatus } from "../../../types/match";
import { IJobPost } from "../../../types/job-post";
import Category from "../../shared/Category";
import { HiOutlineCurrencyDollar, HiBriefcase, HiOutlineLocationMarker } from 'react-icons/hi';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa'
import { IconType } from "react-icons";
import MatchPercentage from "../../shared/MatchPercentage";
import { text } from "../../util/texts";
import { startCase } from "lodash";
import { locationTag, salaryTag, statusTag, workTypeTag } from "../../util/tags";
import { getCategoryMatches, getCategoryRating } from "../../../utils/categories";

interface JobMatchProps {
  jobMatch: IJobMatch,
  onApply: () =>  void,
  onSave: () => void,  
  onReject: () => void,
}

let dollarUSLocale = Intl.NumberFormat('en-US');

const JobMatchDetail = ({
  jobMatch,
  onApply,
  onSave,
  onReject,
}: JobMatchProps) => {
  const color = jobMatch.matchPercentage > 80 ? 'green' : 'orange';
  const renderStatus = () => {
    return (
      <HStack mb={1}>
        { jobMatch.userStatus && statusTag(jobMatch.userStatus.status, { colorScheme: 'teal', size: 'md' })}
        { jobMatch.recruiterStatus?.status as JobMatchStatus === JobMatchStatus.RecruiterInvited && statusTag('Invited to Apply', { colorScheme: 'purple', size: 'md' })}
      </HStack>
    )
  }

  const renderActions = () => {
    const status = jobMatch.userStatus?.status || undefined;
    return (
      <HStack>
        { (!status || status !== JobMatchStatus.CandidateApplied.toString()) && <Button colorScheme="green" onClick={() => onApply()}>Apply</Button> }
        { (!status || (status !== JobMatchStatus.Saved.toString() && status !== JobMatchStatus.CandidateApplied.toString())) && <Button variant="outline" colorScheme="blue">Save</Button> }
        { (!status || (status !== JobMatchStatus.Passed.toString() && status !== JobMatchStatus.CandidateApplied.toString())) && <Button variant="outline" colorScheme="orange">Pass</Button> }
      </HStack>
    )
  }

  return (
    <Box px={2}>
      <Box py={2} mb={5}>
        <Grid gridTemplateColumns="6fr 1fr">
          <GridItem>
            {renderStatus()}
            {text('page.title', jobMatch.jobPost.companyJobTitle, 1)}
            {text('page.subtitle', jobMatch.company.name, 1)}
            {text('page.instruction', jobMatch.jobPost.location)}            
            <HStack mb={3}>
              {salaryTag(dollarUSLocale.format(jobMatch.jobPost.salary))}
              {workTypeTag(jobMatch.jobPost.workType)}
              {locationTag(jobMatch.jobPost.locationType)}
            </HStack>
            {renderActions()}            
          </GridItem>
          <GridItem>
            <MatchPercentage percentage={jobMatch.matchPercentage} color={color} />
          </GridItem>
        </Grid>        
      </Box>
      <Divider mb={5} />
      <Box mb={7}>
        {text('section.title', 'Job Description')}
        {text('section.instruction', jobMatch.jobPost.companyJobDescription)}
      </Box>
      {text('section.title', 'Competency Categories')}
      <Box mb={7}>
        <Button leftIcon={<FaRegMinusSquare />} variant="link">Collapse All</Button>
        <Button ml={3} leftIcon={<FaRegPlusSquare />} variant="link">Expand All</Button>
      </Box>      
      <Accordion allowMultiple allowToggle>
        {jobMatch.job.categories.map(category => {
          return (
            <Category
              category={category}
              categoryRating={getCategoryRating(category, jobMatch.jobRating)}
              categoryCompetencyMatches={getCategoryMatches(category, jobMatch)}
            />
          )
        })}
      </Accordion>
    </Box>
  )
}

export default JobMatchDetail;