import React, { createContext, useContext, useState } from 'react';

import { api } from '../api';
import { CompetencyRatingMap } from '../components/shared/CategoryRater';
import { IJobRating } from '../types/rating';
import { IUser } from '../types/user';

export type RatingContextValue = {
  rating: IJobRating | undefined;
  ratings: IJobRating[] | undefined;
  isFetchingRatings: boolean;
  fetchUserJobRatings: () => void;
  fetchUserJobRating: (jobRatingId: string) => void;
  createUserJobRating: (jobId: string, competencyRatings: CompetencyRatingMap) => Promise<void>;
  updateUserJobRating: (jobRatingId: string, competencyRatings: CompetencyRatingMap) => Promise<void>;
};

const RatingContext = createContext<RatingContextValue | undefined>(undefined);

export const useRating = () => useContext(RatingContext);

// eslint-disable-next-line react/function-component-definition
const RatingProvider = ({ children }: any) => {
  const [isFetchingRatings, setIsFetchingRatings] = useState(true);
  const [rating, setRating] = useState();
  const [ratings, setRatings] = useState();

  /* eslint-disable consistent-return */
  const fetchUserJobRatings = async () => {
    try {
      setIsFetchingRatings(true);

      const res = await api.get(`/ratings`);
      setRatings(res.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
    }
  };
  /* eslint-enable consistent-return */

  /* eslint-disable consistent-return */
  const fetchUserJobRating = async (jobRatingId: string) => {
    try {
      setIsFetchingRatings(true);

      const res = await api.get(`/ratings/${jobRatingId}`);
      setRating(res.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
    }
  };  

  const createUserJobRating = async (jobId: string, competencyRatings: CompetencyRatingMap): Promise<void> => {
    try {
      setIsFetchingRatings(true);

      await api.post('/ratings', { jobId, competencyRatings });
      const res = await api.get(`/ratings`);
      setRatings(res.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
    }
  };

  const updateUserJobRating = async (jobRatingId: string, competencyRatings: CompetencyRatingMap): Promise<void> => {
    try {
      setIsFetchingRatings(true);

      await api.put(`/ratings/${jobRatingId}`, { competencyRatings });
      const res = await api.get(`/ratings`);
      setRatings(res.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
    }
  };
  /* eslint-enable consistent-return */

  return (
    <RatingContext.Provider
      value={{
        rating,
        ratings,
        isFetchingRatings,
        fetchUserJobRatings,
        fetchUserJobRating,
        createUserJobRating,
        updateUserJobRating,
      }}
    >
      {children}
    </RatingContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default RatingProvider;
