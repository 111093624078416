import { Box, Button, Center, Flex, Heading, HStack, Link, Text, VStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { text } from "../util/texts";
import { borders } from "../../styles/common";

interface StatProps {
  name: string,
  value: string,
  size?: string,
  link?: string,
  linkLabel?: string,
}

const Stat = ({
  name,
  value,
  size,
  link,
  linkLabel
}: StatProps) => {
  const isSm = size === 'sm'; 
  const statValueType = isSm ? 'stat.value.sm' : 'stat.value';
  const labelType = isSm ? 'label.sm' : 'label.lg';

  return (
    <Box display="inline-block">
      <VStack>
        {text(statValueType, value, 0, 'green.500')}
        {text(labelType, name, 0)}
        { link && linkLabel && (
          <Link as={RouterLink} to={link}>{text('body.sm', linkLabel, 0)}</Link>
        )}
      </VStack>      
    </Box>
  )
};

export default Stat;