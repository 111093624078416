import { IFilter, IFilterOption } from "../../../types/ui/search";
import { HiOutlineCurrencyDollar, HiBriefcase, HiOutlineLocationMarker, HiOutlineTag, HiOutlineDocumentText } from 'react-icons/hi';
import { JobMatchStatus } from "../../../types/match";
import { tagWithIcon } from "../../util/tags";

export const profileMatchFilters: IFilter[] = [
  {
    name: 'Status',
    slug: 'status',
    options: [
      { name: 'Needs Review', value: JobMatchStatus.NeedsReview.toString() },
      { name: 'Saved', value: JobMatchStatus.Saved.toString() },
      { name: 'Applied', value: JobMatchStatus.CandidateApplied.toString() },
      { name: 'Passed', value: JobMatchStatus.Passed.toString(), },
      { name: 'Invited to Apply', value: JobMatchStatus.RecruiterInvited.toString(), overridePath: 'recruiterStatus.status' },
    ],
    path: 'userStatus.status',
    isMultiChoice: false,
    defaultValue: JobMatchStatus.NeedsReview.toString(),    
  },
  {
    name: 'Location Type',
    slug: 'location-type',
    options: [
      { name: 'Remote', value: 'remote' },
      { name: 'Office', value: 'office' },
      { name: 'Hybrid', value: 'hybrid' },
    ],
    isMultiChoice: true,
    defaultValue: ['remote', 'office', 'hybrid'],
    path: 'jobPost.locationType',
  },
  {
    name: 'Job Type',
    slug: 'job-type',
    options: [
      { name: 'Contract', value: 'contract' },
      { name: 'Full-Time', value: 'full-time' },
      { name: 'Part-Time', value: 'part-time' },
    ],
    isMultiChoice: true,
    defaultValue: ['contract', 'full-time', 'part-time'],
    path: 'jobPost.workType',
  },
]