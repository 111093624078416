import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import { UserContextValue, useUser } from '../stores/UserStore';
import RecruiterHomeContainer from "./recruiter/RecruiterHomeContainer";
import UserHomeContainer from "./user/UserHomeContainer";

const HomeContainer = () => {

  const {
    currentUser,
  } = useUser() as UserContextValue;

  if (!currentUser) {
    return null;
  }

  if (currentUser.userRole === 'user') {
    return <UserHomeContainer />
  }
  else if (currentUser.userRole === 'recruiter') {
    return <RecruiterHomeContainer />
  }
  else {
    return null;
  }

}

export default HomeContainer;