import React, { createContext, useContext, useState } from 'react';

import { api } from '../api';
import { IJobMatch } from '../types/match';
import { IFilter, ISearchValue } from '../types/ui/search';
import { IUser } from '../types/user';
import { toSearchUrl } from '../utils/search';

export type MatchContextValue = {
  matches: IJobMatch[] | undefined;
  filters: IFilter[];
  isFetchingMatches: boolean;
  fetchUserJobMatches: (userId: string, searchValue?: ISearchValue) => void;
  fetchRecruiterJobMatch: (recruiterId: string, searchValue?: ISearchValue) => void;
};

const MatchContext = createContext<MatchContextValue | undefined>(undefined);

export const useMatch = () => useContext(MatchContext);

// eslint-disable-next-line react/function-component-definition
const MatchProvider = ({ children }: any) => {
  const [isFetchingMatches, setIsFetchingMatches] = useState(true);
  const [matches, setMatches] = useState();
  const [filters, setFilters] = useState([]);

  /* eslint-disable consistent-return */
  const fetchUserJobMatches = async (userId: string, searchValue?: ISearchValue) => {
    console.log('fetchUserJobMatches')
    try {
      setIsFetchingMatches(true);

      const res = await api.get(`/matches/${userId}${toSearchUrl(searchValue)}`);
      const { result, filtersWithCount } = res.data;
      setMatches(result);
      setFilters(filtersWithCount);
      setIsFetchingMatches(false);
    } catch (error) {
      setIsFetchingMatches(false);
    }
  };
  /* eslint-enable consistent-return */

  /* eslint-disable consistent-return */
  const fetchRecruiterJobMatch =  async (recruiterId: string, searchValue?: ISearchValue) => {
    try {
      setIsFetchingMatches(true);

      const res = await api.get(`/matches/${recruiterId}${toSearchUrl(searchValue)}`);
      const { result, filtersWithCount } = res.data;
      setMatches(result);
      setFilters(filtersWithCount);
      setIsFetchingMatches(false);
    } catch (error) {
      setIsFetchingMatches(false);
    }
  };
  /* eslint-enable consistent-return */

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <MatchContext.Provider
      value={{
        matches,
        filters,
        isFetchingMatches,
        fetchUserJobMatches,
        fetchRecruiterJobMatch
      }}
    >
      {children}
    </MatchContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default MatchProvider;
