import React, { createContext, useContext, useState } from 'react';
import _ from 'lodash';
import { api } from '../api';
import { IRecruiter, IUser } from '../types/user';

export type UserContextValue = {
  demoUsers: any;
  currentUser: IUser | IRecruiter | undefined;
  isFetchingCurrentUser: boolean;
  isFetchingDemoUsers: boolean;
  fetchDemoUsers: () => Promise<any>;
  fetchCurrentUser: () => Promise<IUser | IRecruiter | undefined>;
  loginUser: (email: string) => void;
  logoutUser: () => void;
};

const UserContext = createContext<UserContextValue | undefined>(undefined);

export const useUser = () => useContext(UserContext);

// eslint-disable-next-line react/function-component-definition
const UserProvider = ({ children }: any) => {
  const [demoUsers, setDemoUsers] = useState(undefined);
  const [currentUser, setCurrentUser] = useState();
  const [isFetchingDemoUsers, setIsFetchingDemoUsers] = useState(true);
  const [isFetchingCurrentUser, setIsFetchingUser] = useState(true);

  /* eslint-disable consistent-return */
  const fetchDemoUsers = async (): Promise<any> => {
    console.log('fetchDemoUsers', demoUsers)

    if (demoUsers !== undefined) {
      return;
    }

    setIsFetchingDemoUsers(true);

    try {
      const res = await api.get('/demoUsers');
      console.log('fetchDemoUsers', res.data)
      setDemoUsers(res.data);
      setIsFetchingDemoUsers(false);
    } catch (error) {
      setIsFetchingDemoUsers(false);
    }
  }

  const fetchCurrentUser = async (): Promise<IUser | undefined> => {
    try {
      if (currentUser !== undefined) {
        return;
      }

      setIsFetchingUser(true);

      const res = await api.get('/me');
      setCurrentUser(res.data);
      setIsFetchingUser(false);
      // return res.data as IUser;
    } catch (error) {
      setIsFetchingUser(false);
    }
  };

  const loginUser = async (email: string) => {
    try {
      setIsFetchingUser(true);

      // const users:any = {
      //   user: 'Alice84@yahoo.com',
      //   recruiter: "Dana.Hoeger5@yahoo.com",
      // };
      // const loginUser = users[userRole];

      const res = await api.post(`/login`, { email, password: 'password'} );

      
      setCurrentUser(res.data);
      setIsFetchingUser(false);

      // return res.data as IUser;
    } catch (error) {
      setIsFetchingUser(false);
    }
  }

  const logoutUser = async () => {
    try {
      setIsFetchingUser(true);
      await api.post(`/logout`, {} );
      setCurrentUser(undefined);
      setIsFetchingUser(false);
    } catch (error) {
      setIsFetchingUser(false);
    }    
  }

  /* eslint-enable consistent-return */

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <UserContext.Provider
      value={{
        demoUsers,
        currentUser,
        isFetchingCurrentUser,
        isFetchingDemoUsers,
        fetchDemoUsers,
        fetchCurrentUser,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default UserProvider;
