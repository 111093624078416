import { Accordion, Avatar, Box, Button, Center, Divider, Grid, GridItem, Heading, HStack, Icon, Link, Stat, StatLabel, StatNumber, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { IJobMatch, JobMatchStatus } from "../../../types/match";
import Category from "../../shared/Category";
import { HiOutlineCurrencyDollar, HiBriefcase, HiOutlineLocationMarker } from 'react-icons/hi';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa'
import { IconType } from "react-icons";
import MatchPercentage from "../../shared/MatchPercentage";
import { getFullName, getUserJobRoles } from "../../../utils/users";
import { text } from "../../util/texts";
import { locationTag, salaryTag, statusTag, workTypeTag } from "../../util/tags";
import { Link as RouterLink } from "react-router-dom";
import { getCategoryMatches, getCategoryRating } from "../../../utils/categories";

interface JobMatchProps {
  jobMatch: IJobMatch,
}

let dollarUSLocale = Intl.NumberFormat('en-US');

const ProfileMatchDetail = ({
  jobMatch
}: JobMatchProps) => {
  const renderStatus = () => {
    return (
      <HStack mb={2}>
        { jobMatch.recruiterStatus && statusTag(jobMatch.recruiterStatus.status, { colorScheme: 'teal', size: 'md' })}
        { jobMatch.userStatus && jobMatch.userStatus.status as JobMatchStatus === JobMatchStatus.CandidateApplied && statusTag(jobMatch.userStatus.status, { colorScheme: 'purple', size: 'md' })}
      </HStack>
    )
  }

  const color = jobMatch.matchPercentage > 80 ? 'green' : 'orange';

  const renderActions = () => {
    const status = jobMatch.userStatus?.status || undefined;
    return (
      <HStack>
        { (!status || status !== JobMatchStatus.RecruiterInvited.toString()) && <Button colorScheme="green">Invite to Apply</Button> }
        { (!status || (status !== JobMatchStatus.Saved.toString() && status !== JobMatchStatus.RecruiterInvited.toString())) && <Button variant="outline" colorScheme="blue">Save</Button> }
        { (!status || (status !== JobMatchStatus.Passed.toString() && status !== JobMatchStatus.RecruiterInvited.toString())) && <Button variant="outline" colorScheme="orange">Pass</Button> }
      </HStack>
    )
  }

  return (
    <Box px={2}>
      <Box py={2} mb={5}>
        <Grid gridTemplateColumns="6fr 1fr">
          <GridItem>
            {renderStatus()}
            <HStack gap={3} mb={1}>              
              {text('page.title', getFullName(jobMatch.user), 0) }
              <Avatar name={getFullName(jobMatch.user)} src={jobMatch.user.avatarUrl} />
            </HStack>              
            {text('page.subtitle', getUserJobRoles(jobMatch.user.ratings).map(j => j.title).join(', '), 1)}
            {text('page.instruction', jobMatch.user.location)}
            <HStack mb={3}>
              {salaryTag(dollarUSLocale.format(jobMatch.user.preferences.salary))}
              {workTypeTag(jobMatch.user.preferences.workType)}
              {locationTag(jobMatch.user.preferences.locationType)}              
            </HStack>            
            {renderActions()}
          </GridItem>
          <GridItem>
            <MatchPercentage percentage={jobMatch.matchPercentage} color={color} />
          </GridItem>
        </Grid>        
      </Box>
      <Divider mb={5} />
      <Text fontSize="2xl" mb={3}>Matched to Job: <Link textDecoration="underline" as={RouterLink} to={`/jobPost/${jobMatch.jobPost.id}`}>{jobMatch.jobPost.companyJobTitle}</Link></Text>
      <Box mb={7}>
        {text('section.title', 'Profile Summary')}
        {text('section.instruction', jobMatch.user.summary)}
      </Box>
      {text('section.title', 'Competency Categories')}
      <Box mb={7}>
        <Button leftIcon={<FaRegMinusSquare />} variant="link">Collapse All</Button>
        <Button ml={3} leftIcon={<FaRegPlusSquare />} variant="link">Expand All</Button>
      </Box>
      <Accordion allowMultiple allowToggle>
        {jobMatch.job.categories.map(category => (
          <Category
            category={category}
            categoryRating={getCategoryRating(category, jobMatch.jobRating)}
            categoryCompetencyMatches={getCategoryMatches(category, jobMatch)}
          />
        ))}
      </Accordion>
    </Box>
  )
}

export default ProfileMatchDetail;