import { IJobMatchCompetency } from "../types/match";
import { MatchOutcome, ProficiencyLevel } from "../types/proficiency-level"

export const getColorForLevel = (level: ProficiencyLevel): string => {
  switch (level) {
    case ProficiencyLevel.Skill:
      return 'green';
    case ProficiencyLevel.Ability:
      return 'blue';
    case ProficiencyLevel.Knowledge:    
      return 'orange';
    case ProficiencyLevel.Not_Applicable:
    default:
      return 'red';
  }
}

export const getMatchOutcome = (rating: ProficiencyLevel, expected: ProficiencyLevel): MatchOutcome => {
  return (rating - expected) as MatchOutcome;
}

const getWeightedMatchValue = (matchOutcome: MatchOutcome, rating: ProficiencyLevel) => {
  if (rating === ProficiencyLevel.Not_Applicable) {
    return 0
  }

  switch (matchOutcome) {
    case 2:
      return 2
    case 1:
      return 1.5
    case 0:
      return 1;
    case -1:
      return .5;
    case -2:
      return .25;
    case -3:
    default:
      return 0;
  }
}

export const getMatchPercentage = (competencies: IJobMatchCompetency[]) => {  
  let weightedMatchSum = 0.0;
  for (const competency of competencies) {
    // weightedMatchSum += getWeightedMatchValue(competency.matchOutcome, competency.rating);
    weightedMatchSum += (competency.matchOutcome >= 0 ? 1 : 0);
  }

  const exactMatch = competencies.length;
  return Math.round((weightedMatchSum / exactMatch) * 100);
}