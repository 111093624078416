import { Box, Container, Heading, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserJobProfile from "../../components/user/profile/UserJobProfile";
import { RatingContextValue, useRating } from "../../stores/RatingProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";

const UserJobProfileContainer = () => {
  const navigate = useNavigate();

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    ratings,
    isFetchingRatings,
    fetchUserJobRatings
  } = useRating() as RatingContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchUserJobRatings()
    }    
  }, []);

  if (isFetchingRatings) {
    return <Spinner />
  }

  if (!currentUser || !ratings) {
    return null;
  }

  const onEditJobRating = (jobRatingId: string) => {
    navigate(`/jobRating/${jobRatingId}`);
  }

  return (
    <Box px={20} py={15}>
      <UserJobProfile user={currentUser} jobRatings={ratings} onEditJobRating={onEditJobRating} />
    </Box>    
  )
};

export default UserJobProfileContainer;