import { Box, Button, Center, Progress, Text } from "@chakra-ui/react";
import { intersection } from "lodash";
import { useState } from "react";
import { IJob } from "../../types/job";
import { ProficiencyLevel } from "../../types/proficiency-level";
import { IGenericCompetencyRating } from "../../types/rating";
import { UserRole } from "../../types/user";
import { groupRatingsByCategory } from "../../utils/categories";
import CategoryRater, { CompetencyRatingMap } from "./CategoryRater";

// export interface CategoryRatingContainer {
//   [categoryId: string]: CompetencyRatingContainer;
// }

interface JobRaterProps {
  job: IJob;
  jobRating?: {
    jobId: string;
    competencyRatings: IGenericCompetencyRating[],
  }, // IJobRating | IJobPost;  
  onSubmit: (updatedValues: CompetencyRatingMap) => void;
  ratingRole: UserRole;
}

const JobRater = ({
  job,
  jobRating,
  onSubmit,  
  ratingRole,
}: JobRaterProps) => {
  const initValues: CompetencyRatingMap = {};  
  // const categoryRatings = groupRatingsByCategory(job, jobRating);
  // if (jobRating) {    
  //   categoryRatings.forEach(category => {
  //     const competencyRatingValues: CompetencyRatingContainer = {};
  //     category.competencyRatings.forEach(c => competencyRatingValues[c.competencyId] = c.rating.toString() as unknown as ProficiencyLevel);
  //     initValues[category.categoryId] = competencyRatingValues;
  //   })    
  // }

  if (jobRating) {
    for (const competencyRating of jobRating.competencyRatings) {
      initValues[competencyRating.competencyId] = competencyRating.rating;
    }
  }

  console.log('initValues', initValues);

  const [values, setValues] = useState<CompetencyRatingMap>(initValues);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(0);  

  const onPrev = () => {
    if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex(currentCategoryIndex-1);
    }
  }

  const onNext = () => {
    // check all comps rated
    if (currentCategoryIndex === job.categories.length - 1) {
      onSubmit(values)
    }
    else {
      setCurrentCategoryIndex(currentCategoryIndex+1);
    }
  }

  const progressPercentage = Math.round(((currentCategoryIndex+1)/job.categories.length)* 100);
  
  const currentCategory = job.categories[currentCategoryIndex];
  // const currentCategoryRating = categoryRatings.find((c: { categoryId: string }) => c.categoryId === currentCategory.id);

  const isNextDisabled = () => {
    console.log('values', values);
    
    if (!values) {
      return true;
    }

    const expectedCategoryCompetencyIds = currentCategory.competencies.map(c => c.id);
    const allCompetencyValueIds = Object.keys(values);
    const comparison = intersection(allCompetencyValueIds, expectedCategoryCompetencyIds)
    return  comparison.length !== expectedCategoryCompetencyIds.length;

    // const categoryValues = values[currentCategory.id];
    // if (!categoryValues) {
    //   return true;
    // }

    // const currentCategoryCompetencyRatings = Object.keys(categoryValues as CompetencyRatingContainer);
    // const expectedCompetenciesWithRating = currentCategory.competencies.map(c => c.id);
    // const comparison = intersection(currentCategoryCompetencyRatings, expectedCompetenciesWithRating);
    // return  comparison.length !== expectedCompetenciesWithRating.length;
  }

  const handleCategoryChange = (competencyRatings: CompetencyRatingMap) => {
    const updatedValues = {
      ...values,
      ...competencyRatings
    }
    // console.log('updated category', JSON.stringify(updatedValues));
    setValues(updatedValues);
  }

  return (
    <Box>      
      <Progress value={progressPercentage} />
      <Center mb={5}>        
        <Text>{currentCategoryIndex+1} of {job.categories.length} Categories</Text>
      </Center>
      <Center>
        <CategoryRater category={currentCategory} ratingsValues={values} onChange={handleCategoryChange} ratingRole={ratingRole} />        
      </Center>
      <Center>
        <Button onClick={onPrev} isDisabled={currentCategoryIndex === 0}>Prev</Button>
        <Button ml={3} onClick={onNext} isDisabled={isNextDisabled()} colorScheme="blue">{currentCategoryIndex === job.categories.length - 1 ? 'Submit' : 'Next'}</Button>
      </Center>     
    </Box>
  );
}

export default JobRater;