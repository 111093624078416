import { Box, Center, Spinner, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { JobContextValue, useJob } from '../../stores/JobProvider';
import { JobPostContextValue, useJobPost } from '../../stores/JobPostProvider';
import { text } from '../../components/util/texts';
import JobSelector from '../../components/shared/JobSelector';
import JobPost from '../../components/recruiter/jobPost/JobPost';
import { CompetencyRatingMap } from '../../components/shared/CategoryRater';

const RecruiterJobContainer = () => {
  const { jobId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    job,
    isFetchingJobs,
    fetchJob,
  } = useJob() as JobContextValue;

  const {
    createJobPost
  } = useJobPost() as JobPostContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJob(jobId as string)
    }
  }, []);

  if (isFetchingJobs) {
    return <Spinner />
  }

  if (!currentUser || !job) {
    return null;
  }

  const onSubmit = (jobPostDetails: any, updatedValues: CompetencyRatingMap) => {
    console.log('onSubmit', jobPostDetails, updatedValues);
    createJobPost(job.id, jobPostDetails, updatedValues).then(() => {
      navigate('/jobPosts')
      toast({
        title: 'Job Post created!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    })
  }
  
  return (
    <Box px={20} py={15}>
      <JobPost job={job} onSubmit={onSubmit} />
    </Box>    
  )
};

export default RecruiterJobContainer;