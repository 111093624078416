import { Box, Center, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { JobContextValue, useJob } from '../../stores/JobProvider';
import { text } from '../../components/util/texts';
import JobSelector from '../../components/shared/JobSelector';
import { RatingContextValue, useRating } from '../../stores/RatingProvider';

const UserJobSelectContainer = () => {
  const navigate = useNavigate();

  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    jobs,
    isFetchingJobs,
    fetchJobs,
  } = useJob() as JobContextValue;

  const {
    ratings,
    isFetchingRatings,
    fetchUserJobRatings
  } = useRating() as RatingContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJobs()
      fetchUserJobRatings()
    }
  }, []);

  if (isFetchingJobs || isFetchingRatings) {
    return <Spinner />
  }

  if (!currentUser || !jobs || !ratings) {
    return null;
  }

  const onSelect = (jobId: string) => {
    navigate(`/jobProfile/create/${jobId}`);
  }

  const existingJobIds = ratings.map(r => r.jobId);

  console.log('existingJobIds', existingJobIds)

  return (
    <Box px={20} py={15}>
      {text('page.title', 'Add Job to Profile')}
      {text('page.instruction', 'Select a job title to start adding relevant competencies to your profile.', 5)}
      <JobSelector jobs={jobs} existingJobIds={existingJobIds} onSelect={onSelect} />
    </Box>    
  )
};

export default UserJobSelectContainer;