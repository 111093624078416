import React, { createContext, useContext, useState } from 'react';

import { api } from '../api';
import { CompetencyRatingMap } from '../components/shared/CategoryRater';
import { IJobPostWithStats } from '../types/job-post';
import { IUser } from '../types/user';

export type JobPostContextValue = {
  jobPost: IJobPostWithStats | undefined;
  jobPosts: IJobPostWithStats[] | undefined;
  isFetchingJobPosts: boolean;
  fetchJobPosts: () => void;
  fetchJobPost: (jobPostId: string) => void;
  createJobPost: (jobId: string, jobPostPayload: any, competencyRatings: CompetencyRatingMap) => Promise<void>;
  updateJobPost: (jobPostId: string, jobPostPayload: any, competencyRatings: CompetencyRatingMap) => Promise<void>;
};

const JobPostContext = createContext<JobPostContextValue | undefined>(undefined);

export const useJobPost = () => useContext(JobPostContext);

// eslint-disable-next-line react/function-component-definition
const JobPostProvider = ({ children }: any) => {
  const [isFetchingJobPosts, setIsFetchingJobPosts] = useState(true);
  const [jobPosts, setJobPosts] = useState();
  const [jobPost, setJobPost] = useState();

  /* eslint-disable consistent-return */
  const fetchJobPosts =  async () => {
    try {
      setIsFetchingJobPosts(true);

      const res = await api.get(`/jobPosts`);
      setJobPosts(res.data);
      setIsFetchingJobPosts(false);
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };
  /* eslint-enable consistent-return */

  const fetchJobPost =  async (jobPostId: string) => {
    try {
      setIsFetchingJobPosts(true);

      const res = await api.get(`/jobPosts/${jobPostId}`);
      setJobPost(res.data);
      setIsFetchingJobPosts(false);
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };

  const createJobPost = async (jobId: string, jobPostPayload: any, competencyRatings: CompetencyRatingMap): Promise<void> => {
    try {
      setIsFetchingJobPosts(true);

      await api.post('/jobPosts', { jobId, jobPost: jobPostPayload, competencyRatings });
      const res = await api.get(`/jobPosts`);
      setJobPosts(res.data);
      setIsFetchingJobPosts(false);
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };

  const updateJobPost = async (jobPostId: string, jobPostPayload: any, competencyRatings: CompetencyRatingMap): Promise<void> => {
    try {
      setIsFetchingJobPosts(true);

      await api.put(`/jobPosts/${jobPostId}`, { jobPost: jobPostPayload, competencyRatings });
      const res = await api.get(`/jobPosts`);
      setJobPosts(res.data);
      setIsFetchingJobPosts(false);
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };
  
  /* eslint-enable consistent-return */

  return (
    <JobPostContext.Provider
      value={{
        jobPost,
        jobPosts,
        isFetchingJobPosts,
        fetchJobPost,
        fetchJobPosts,
        createJobPost,
        updateJobPost,
      }}
    >
      {children}
    </JobPostContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default JobPostProvider;
