import { Box } from '@chakra-ui/react';
import CompanyProfile from '../components/recruiter/profile/CompanyProfile';
import UserProfile from '../components/user/profile/UserProfile';
import { text } from '../components/util/texts';
import { UserContextValue, useUser } from '../stores/UserStore';
import { IRecruiter } from '../types/user';

const ProfileContainer = () => {

  const {
    currentUser,
  } = useUser() as UserContextValue;

  if (!currentUser) {
    return null;
  }

  if (currentUser.userRole === 'user') {
    const onSubmit = (values: any) => {
      const user = {
        email: values.email,
        firstName: values.firstName,
        lastName : values.lastName,
        location: values.location,
        summary: values.summary,
        preferences: {
         locationType: values.locationType,
         workType: values.workType,
         salary: values.salary,
        }
      };
      
      console.log('user to update', user);
    
      // TODO: Send Post request to api/me to update values
    }
    
    return (
      <Box px={20} py={15}>
        {text('page.title', 'Profile')}
        <UserProfile user={currentUser} onSubmit={onSubmit} />
      </Box>
    )
  }
  else if (currentUser.userRole === 'recruiter') {
    const recruiter = currentUser as IRecruiter;
    const onSubmit = (values: any) => {
      console.log('company profile submit', values);
    }

    return (
      <Box px={20} py={15}>
        {text('page.title', 'Profile')}
        <CompanyProfile company={recruiter.company} onSubmit={onSubmit} />
      </Box>
    )
  }
  else {
    return null;
  }

}

export default ProfileContainer;