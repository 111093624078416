import { Box, HStack, Progress, Text } from "@chakra-ui/react";

interface MatchPercentageProps {
  percentage: number;
  color: string;
  size?: 'sm'  | 'md';
}

const MatchPercentage = ({
  percentage,
  color,
  size = 'md',
}: MatchPercentageProps) => {
  const px = size === 'sm' ? [1, 2] : [3, 4];
  const py = size === 'sm' ? [.5, 1] : [2, 3];
  const fontSize = size === 'sm' ? { base: 'sm', md: 'md' } : { base: 'lg', md: '2xl' };

  return (
    <Box
      borderRadius={6}
      px={px}
      py={py}
      display="inline-block"
      // backgroundColor={`${color}.400`} color="white"
      textAlign="center"
    >
      <Text fontSize={fontSize} fontWeight="bold">{percentage}%</Text>
      { size !== 'sm' && <Text fontSize={{ base: 'xs', md: 'sm' }}>Match</Text> }
      <Progress colorScheme={color} size='sm' value={percentage} />
    </Box>
  );
}

export default MatchPercentage;