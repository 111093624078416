import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ProfileMatchPage from "../../components/recruiter/match/ProfileMatchPage";
import { useSearchParams } from "react-router-dom";
import JobMatchPage from "../../components/user/match/JobMatchPage";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { IFilter, IFilterValue, ISearchValue } from "../../types/ui/search";

const getDefaultFilterValues = (filters: IFilter[]) => {
  const filterDefaultValues: IFilterValue[] = [];
  filters.forEach(f => {
    if (f.defaultValue) {
      filterDefaultValues.push({
        slug: f.slug,
        selectedValue: f.defaultValue
      })
    }
  });
  return filterDefaultValues;
}

const dedupArray = (filterValues: IFilterValue[]) => {
  return filterValues.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.slug === value.slug
    ))
  )
}

const RecruiterMatchContainer = () => {
  
  const {
    currentUser,
  } = useUser() as UserContextValue;

  const {
    matches,
    filters,
    isFetchingMatches,
    fetchRecruiterJobMatch,
  } = useMatch() as MatchContextValue;

  let [searchParams, setSearchParams] = useSearchParams();
  const statusQuery = searchParams.get('status');
  console.log('statusQuery', statusQuery)
  const initialFilterValues = statusQuery ? dedupArray([ { slug: 'status', selectedValue: statusQuery }, ...getDefaultFilterValues(filters)]) : getDefaultFilterValues(filters);
  console.log('initialFilterValues', initialFilterValues)
  const [filterValues, setFilterValues] = useState<IFilterValue[]>(initialFilterValues);
  
  useEffect(() => {
    if (currentUser) {
      fetchRecruiterJobMatch(currentUser.id)
    }    
  }, []);

  if (isFetchingMatches) {
    return <Spinner />
  }

  if (!currentUser || !matches) {
    return null;
  }

  const onSearch = (searchValue: ISearchValue) => {
    setFilterValues(searchValue.filterValues as IFilterValue[]);
    fetchRecruiterJobMatch(currentUser.id, searchValue)
  }

  const onApply = (jobPostId: string) => {
    console.log('onApply', jobPostId)
  }

  const onSave = (jobPostId: string) => {
    console.log('onSave', jobPostId)
  }

  const onReject = (jobPostId: string) => {
    console.log('onReject', jobPostId)
  }

  return (
    <ProfileMatchPage
      jobMatches={matches}
      filters={filters}
      filterValues={filterValues}
      onSearch={onSearch}
      onApply={onApply}
      onSave={onSave}
      onReject={onReject}
    />
  )
};

export default RecruiterMatchContainer;