import { Box, Checkbox, CheckboxGroup, Heading, HStack, Icon, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { StringOrNumber } from "@chakra-ui/utils";
import { ReactNode } from "react";
import { IFilter, IFilterOption, IFilterValue } from "../../types/ui/search";
import { text } from "../util/texts";

interface FilterDetailProps {
  filter: IFilter,
  filterValue?: IFilterValue,
  onSelect: (value: string | string[]) => void
  optionRenderer?: (option: IFilterOption) => ReactNode
}

const FilterDetail = ({
  filter,
  filterValue,  
  onSelect,  
  optionRenderer,
}: FilterDetailProps) => {  

  const renderOptionLabel = (option: IFilterOption) => {
    const count = option.count || 0;
    return optionRenderer ? optionRenderer(option) : text('body.regular', `${option.name} (${count})`);
  }

  const renderSingleChoice = () => {
    let selectedOption = (filterValue?.selectedValue as string) || (filter.defaultValue as string) || undefined;
    return (
      <RadioGroup id={filter.slug} onChange={onSelect} defaultValue={selectedOption}>
        <Stack>
          {filter.options.map(option => {            
            return (
              <Radio value={option.value}>{renderOptionLabel(option)}</Radio>
            )
          })}
        </Stack>
      </RadioGroup>
    )
  }

  const renderMultiChoice = () => {
    let selectedOptions = (filterValue?.selectedValue as string[]) || (filter.defaultValue as string[]) || undefined;
    
    const handleCheck = (value: StringOrNumber[]) => {
      const transformedValue = value ? (value as string[]) : [];
      onSelect(transformedValue);
    }
    
    return (
      <CheckboxGroup onChange={handleCheck} value={selectedOptions}>
        <Stack>
          {filter.options.map(option => {
            return (
              <Checkbox value={option.value}>{renderOptionLabel(option)}</Checkbox>
            )
          })}
        </Stack>
      </CheckboxGroup>
    )
  }

  return (
    <Box ml={0}>
      <HStack align="center">                  
        {filter.displayIcon && <Icon as={filter.displayIcon} />}
        {text('label.regular', filter.name, 2)}
      </HStack>
      {/* {label(filter.name, 2)} */}
      {filter.isMultiChoice ? renderMultiChoice() : renderSingleChoice()}
    </Box>
  )
};

export default FilterDetail;