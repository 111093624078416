import React, { createContext, useContext, useState } from 'react';

import { api } from '../api';
import { IJob } from '../types/job';
import { IUser } from '../types/user';

export type JobContextValue = {
  job: IJob | undefined;
  jobs: IJob[] | undefined;
  isFetchingJobs: boolean;
  fetchJobs: () => void;
  fetchJob: (jobId: string) => void;
};

const JobContext = createContext<JobContextValue | undefined>(undefined);

export const useJob = () => useContext(JobContext);

// eslint-disable-next-line react/function-component-definition
const JobProvider = ({ children }: any) => {
  const [isFetchingJobs, setIsFetchingJobs] = useState(true);
  const [jobs, setJobs] = useState();
  const [job, setJob] = useState();

  /* eslint-disable consistent-return */
  const fetchJobs =  async () => {
    try {
      setIsFetchingJobs(true);

      const res = await api.get(`/jobs`);
      setJobs(res.data);
      setIsFetchingJobs(false);
    } catch (error) {
      setIsFetchingJobs(false);
    }
  };
  /* eslint-enable consistent-return */

  const fetchJob =  async (jobId: string) => {
    try {
      setIsFetchingJobs(true);

      const res = await api.get(`/jobs/${jobId}`);
      setJob(res.data);
      setIsFetchingJobs(false);
    } catch (error) {
      setIsFetchingJobs(false);
    }
  };
  /* eslint-enable consistent-return */

  return (
    <JobContext.Provider
      value={{
        job,
        jobs,
        isFetchingJobs,
        fetchJob,
        fetchJobs
      }}
    >
      {children}
    </JobContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default JobProvider;
