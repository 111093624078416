import { IJob } from "../types/job";
import { IJobRating } from "../types/rating";
import { IUser, UserRole } from "../types/user";

export const getFullName = (user: IUser): string => {
  return `${user.firstName} ${user.lastName}`;
}

export const isRecruiter = (user?:  IUser) => {
  return user && user.userRole === UserRole.Recruiter;
}

export const getUserJobRoles = (ratings?: IJobRating[]): IJob[] => {
  if (!ratings || ratings.length === 0) {
    return [];
  }

  const jobRoleTitles = ratings.map(r => r.job);
  jobRoleTitles.sort();

  return jobRoleTitles;
}