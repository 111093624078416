import { AddIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Accordion, Box, Button, Divider, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Select, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { startCase } from "lodash";
import { useState } from "react";
import { IJob } from "../../../types/job";
import { IJobRating } from "../../../types/rating";
import { IUser } from "../../../types/user";
import { getFullName, getUserJobRoles } from "../../../utils/users";
import { locationTag, salaryTag, workTypeTag } from "../../util/tags";
import { text } from "../../util/texts";
import Category from "../../shared/Category";
import { HiPlusCircle, HiPencil } from "react-icons/hi";
import {  } from "react-icons/hi";
import { Link as RouterLink } from "react-router-dom";
import { getCategoryRating, groupRatingsByCategory } from "../../../utils/categories";

interface UserJobProfileProps {
  user: IUser;
  jobRatings: IJobRating[];
  onEditJobRating: (jobRatingId: string) => void
}

let dollarUSLocale = Intl.NumberFormat('en-US');

const UserJobProfile = ({
  user,
  jobRatings,
  onEditJobRating,
}: UserJobProfileProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const jobRoles = getUserJobRoles(jobRatings);  
  const [jobRoleSlug, setJobRoleSlug] = useState(jobRoles[0].slug);
  const jobRating = jobRatings.find(jr => jr.job.slug === jobRoleSlug);

  console.log('jobRating', jobRating)
  const handleSelect = (value: string) => {
    setJobRoleSlug(value);
  }

  return (
    <Box>
      <Box mb={5}>
        {text('page.title', getFullName(user))}
        {/* <HStack mb={3}> */}
          {text('page.subtitle', jobRoles.map(j => j.title).join(', '))}
          {text('page.instruction', user.location)}
          {/* {pageSubHeading('|', 0)} */}          
        {/* </HStack>       */}
        <HStack mb={4}>
          {salaryTag(dollarUSLocale.format(user.preferences.salary), { size: 'lg' })}
          {workTypeTag(startCase(user.preferences.workType), { size: 'lg' })}
          {locationTag(startCase(user.preferences.locationType), { size: 'lg' })}
        </HStack>
        <HStack gap={3}>
          <Button variant="link" leftIcon={<HiPlusCircle />} colorScheme="green" as={RouterLink} to="/jobProfile/selectJob">Add Job to Profile</Button>
          <Button variant="link" leftIcon={<HiPencil />} colorScheme="green" as={RouterLink} to="/profile" >Edit Preferences</Button>
          <Button variant="link" leftIcon={<ExternalLinkIcon />} colorScheme="blue" onClick={onOpen}>Share Profile</Button>
        </HStack>
      </Box>
      <Divider mb={5} />
      <Box mb={7}>
        {text('section.title', 'Profile Summary')}
        {text('section.instruction', user.summary)}        
      </Box>      
      {jobRating && (
        <>          
          {text('section.title', 'Your Job Ratings', 5)}
          <Box mb={7}>
            {text('label.regular', 'Select Job Title')}
            <RadioGroup onChange={handleSelect} value={jobRoleSlug}>
              <Stack>
                {jobRoles.map(j => (
                  <Radio size='lg' colorScheme="green" value={j.slug}>{j.title}<Button ml={2} variant="link" leftIcon={<HiPencil />} colorScheme="blue" onClick={() => onEditJobRating(jobRating.id)}>Edit</Button></Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
          {text('section.title', 'Competency Categories', 5)}
          <Accordion allowMultiple allowToggle>
            {jobRating.job.categories.map(category => (
              <Category category={category} categoryRating={getCategoryRating(category, jobRating)} />
            ))}
          </Accordion>
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share Your Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {text('body.lg', 'Share your profile with others or include on your resume or LinkedIn.', 3)}
            {text('body.regular', 'Sharable Link: https://app.kibo.com/profile/12397135')}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Copy Sharable Link
            </Button>
            <Button variant='ghost' onClick={onClose}>Done</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
    </Box>
  )
};

export default UserJobProfile;