import { ICompany } from "./company";
import { IJobPost } from "./job-post";
import { IJobRating } from "./rating";

export enum UserRole {
  User = 'user',
  Recruiter = 'recruiter',
}

export interface IUser {
  id: string;
  username: string;
  userRole: string; // UserRole

  firstName: string;
  lastName: string;
  email: string;

  avatarUrl: string;

  location: string;
  summary: string;  

  preferences: {
    locationType: string;
    workType: string;
    salary: number;
  }

  // Joins
  ratings?: Omit<IJobRating, "user">[];
}

export interface IRecruiter extends IUser {
  companyId: string;

  // Joins
  company: ICompany;
  jobPosts?: Omit<IJobPost, "recruiter">[];
}