import { isArray, isEmpty } from "lodash";
import { ISearchValue } from "../types/ui/search";

export const toSearchUrl = (searchRequest?: ISearchValue): string => {
  if (!searchRequest) return '';

  const params: string[] = [];
  if (searchRequest.searchTerm) {
    params.push(`searchTerm=${searchRequest.searchTerm}`);
  }
  if (searchRequest.filterValues) {
    for (const filterValue of searchRequest.filterValues) {
      // if (isArray(filterValue.selectedValue)) {
      //   for (const filterValueItem of filterValue.selectedValue) {
      //     params.push(`${filterValue.slug}=${filterValueItem}`)
      //   }
      // }
      // else {
        params.push(`${filterValue.slug}=${filterValue.selectedValue}`)
      // }
    }
  }

  if (params.length === 0) return '';

  const searchParams = `?${params.join('&')}`;
  console.log('searchParams', searchParams);
  return searchParams;
}